body, div, dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, pre,
form, fieldset, input, p, blockquote, table, th, td, embed, object, * {
  padding: 0;
  margin: 0;
  outline: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
fieldset, img, abbr {
  border: 0;
}
address, caption, cite, code, dfn, em,
h1, h2, h3, h4, h5, h6, strong, th, var {
  font-weight: normal;
  font-style: normal;
}
ul {
  list-style: none;
}
caption, th {
  text-align: left;
}
h1, h2, h3, h4, h5, h6 {
  font-size: 1.0em;
}
q:before, q:after {
  content: '';
}
a, ins {
  text-decoration: none;
}
body{
  background-color: #fff;
}
.App {
  text-align: center;
}

.App-header {
  /*min-height: 100vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: #222;
}
