@keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;transform:none}
}
@-webkit-keyframes fade-in {
    0% {opacity:0;}
    100% {opacity:1;transform:none}
}
@keyframes fade-out {
    0% {opacity:1;}
    100% {opacity:0;transform:none}
}
@-webkit-keyframes fade-out {
    0% {opacity:1;}
    100% {opacity:0;transform:none}
}

@keyframes in-top {
    from{opacity:0;transform:translate3d(0,-50%,0)}
    to{opacity:1;transform:none;}
}
@-webkit-keyframes in-top {
    from{opacity:0;transform:translate3d(0,-50%,0)}
    to{opacity:1;transform:none;}
}

/* spinner */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.bilego-spinner .circle:nth-of-type(0) {
    transform: rotate(0deg);
}
.bilego-spinner .circle:nth-of-type(0) div {
    animation: spin 2s infinite linear;
}
.bilego-spinner .circle:nth-of-type(1) {
    transform: rotate(70deg);
}
.bilego-spinner .circle:nth-of-type(1) div {
    animation: spin 2s infinite linear;
}
.bilego-spinner .circle:nth-of-type(2) {
    transform: rotate(140deg);
}
.bilego-spinner .circle:nth-of-type(2) div {
    animation: spin 2s infinite linear;
}
.bilego-spinner {
    animation: spin 5s infinite linear;
}
.ant-spin-nested-loading > div .ant-spin{
    max-height: inherit;
}

/**/
._visible {
    pointer-events: auto;
    transform: none;
    opacity: 1;
}
/**/

@keyframes in {
    from{opacity:0;transform:translate3d(0,50%,0)}
    to{opacity:1;transform:none}
}
@-webkit-keyframes in {
    from{opacity:0;transform:translate3d(0,50%,0)}
    to{opacity:1;transform:none}
}

@viewport {
    user-zoom: fixed;
}
.bilego-basket .ant-drawer-content{
    overflow: visible;
}
.ant-modal-header{
    border-radius: 0;
}
.ant-modal-content{
    background: #fff;
}
/*@keyframes flash {*/
/*    from,50%,to{opacity:1}*/
/*    25%,75%{opacity:0}*/
/*}*/
/*.fade-left{*/
/*    opacity:1;transform:none;*/
/*}*/
#root{height: 100%}
.bt-map > * {opacity: 1;transition: opacity 0.6s}
.bt-map input, .bt-map button, .bt-map a, .bt-map a:hover, .bt-map a:active, .bt-map a:focus{outline:none;box-shadow:none;-webkit-box-shadow:none;}

.btm-map,.bt-container.map,.bt-container.set{height: 100%;}
.btm-map{transform-origin: 0 0;}
.btm-map .btm-map-image{position: absolute;  width: 100%;  height: 100%;  -webkit-touch-callout: none;  -webkit-user-select: none;  -khtml-user-select: none;  -moz-user-select: none;  -ms-user-select: none;  user-select: none;  }

.dragging{cursor: grabbing}
#bts-tickets-map{background:center center no-repeat;background-size:contain;width:100%;height:100%;}
.event-wrapper{position: relative}

#bt-tooltip{margin:0;position:absolute;z-index:9999;width:170px;-webkit-font-smoothing:antialiased;}
.bt-tooltip-wrap{background:rgba(255,255,255,0.9);padding:1px;position:relative;box-shadow:0 4px 10px rgba(0,0,0,0.15);}
.bt-tooltip-wrap::after,.bt-tooltip-wrap::before{content:'';width:0;height:0;display:block;overflow:hidden;border-width:6px;border-style:solid;margin-top:0;margin-left:-6px;position:absolute;top:100%;right:50%;bottom:auto;left:50%}
.bt-tooltip-wrap::after{border-color:#fff transparent transparent;z-index:4;-webkit-transform:translate(0,-1px);-ms-transform:translate(0,-1px);transform:translate(0,-1px)}
.bt-tooltip-wrap::before{border-color:rgba(0,0,0,0.1) transparent transparent;z-index:2}
.bt-tooltip-wrap{padding:12px;text-align:center;line-height:1.18em}
.bt-tooltip-wrap .title,.bt-tooltip-wrap .content{color:#111;display:block;font-size:14px}
.bt-tooltip-wrap .title{font-weight: 500}
.bt-tooltip-wrap .descr, .bt-tooltip-wrap.bt-tooltip-wrap-seat .content{color:rgba(0,0,0,0.4);display:block;font-size:12px;margin-top:0px}
.bt-tooltip-wrap .content, .bt-tooltip-wrap.bt-tooltip-wrap-seat .content{margin-top:3px}
.bt-tooltip-wrap-seat{font-weight: 500}
#bt-tooltip .container.seat-tooltip{width:150px}

.ant-notification-notice.bt-informer{border-radius:0;background:rgba(0,0,0,0.45)}
.bt-informer .ant-notification-notice-message,.bt-informer .ant-notification-notice-description,.bt-informer .ant-notification-notice-close{color:#fff}
